import React, { useContext } from "react"
import { CartContext } from "./CartProvider"
import { loadStripe } from "@stripe/stripe-js"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const Checkout = () => {
  const { cart, total } = useContext(CartContext)
  
  const onClick = () => {
    const lineItems = []
    const payloadItems = []
    
    cart.map((item) => {
      lineItems.push({
        price: item[0].id,
        quantity: item[1]
      })

      payloadItems.push({
        'item_name': item[0].product.name, // Name or ID is required.
        'item_id': item[0].product.id,
        'price': item[0].unit_amount / 100,
        'currency': item[0].currency,
        'quantity': item[1]
      })
    })
    
    const payload = {
      'event': 'begin_checkout',
      'ecommerce': {
        'items': payloadItems
      }
    }
    
    if (process.env.NODE_ENV === "development") {
      console.log("Tracking: Begin Checkout")
      console.log(payload)
    } else {
      dataLayer.push(payload)
    }

    fetch("/.netlify/functions/orderCreate", {
      method: "POST",
      body: JSON.stringify(lineItems),
    })
      .then(async response => {
        const { id } = await response.json()
        localStorage.setItem("cart", "{}")
        const stripe = await stripePromise
        const { error } = await stripe.redirectToCheckout({ sessionId: id })
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        alert(error.message)
      })
      .catch(err => alert(err.message))
  }

  return <button onClick={onClick} id="checkout" data-checkout-currency="USD" data-checkout-value={total / 100}>Checkout for ${total / 100}</button>
}

export default Checkout
