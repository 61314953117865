import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"

// import PromoBar from "./PromoBar"
import { head, title, logobox, logo, links } from "./Header.module.css"
import Cart from "./Cart"

function Header(props) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <header className={head}>
      <nav className="container">
        <Link to="/" className={title}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.0118 76.0184" className={logobox}>
            <title>{data.site.siteMetadata.title}</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1"><path className={logo} d="M38.09,27.0478V50.8543H47.613V27.0478a23.8065,23.8065,0,1,0-47.613,0V50.8543H9.5226V27.0478a14.2839,14.2839,0,1,1,28.5678,0Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M29.5081,55.453V31.6465H19.9855V55.453a23.8065,23.8065,0,1,0,47.613,0V31.6465H58.0759V55.453a14.2839,14.2839,0,1,1-28.5678,0Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M83.6837,19.92l-1.65,5.37h-3.57c.66-2.01,5.67-18.3291,6.42-20.6093h4.68l6.75,20.6093h-3.84l-1.71-5.37Zm6.2988-3.03c-1.4394-4.59-2.3994-7.56-2.85-9.39h-.03c-.51,2.34-1.4991,5.55-2.6993,9.39Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M98.713,14.6406c0-2.94-.03-4.08-.06-4.77h3.42a13.3738,13.3738,0,0,1,.12,1.95,4.76,4.76,0,0,1,4.3193-2.25,3.7792,3.7792,0,0,1,3.87,2.49,5.0684,5.0684,0,0,1,4.59-2.49c2.5195,0,4.8291,1.32,4.8291,5.34V25.29h-3.48V15.4805c0-1.53-.54-2.97-2.3994-2.97-2.07,0-2.91,1.56-2.91,3.8995v8.88h-3.45V15.36c0-1.56-.6894-2.85-2.43-2.85-1.89,0-2.9394,1.29-2.9394,4.11V25.29H98.713Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M135.3419,21.48a31.849,31.849,0,0,0,.21,3.81h-3.12a12.7229,12.7229,0,0,1-.2393-2.04c-.6006,1.35-2.1006,2.31-4.41,2.31-3.8095,0-5.1-2.43-5.1-4.71,0-2.61,1.77-5.01,7.1993-5.01h1.98V14.61c0-1.32-.3906-2.49-2.46-2.49-1.95,0-2.52.96-2.7,2.28h-3.42c.1807-2.49,1.65-4.83,6.18-4.83,3.51,0,5.88,1.26,5.88,4.98Zm-3.3594-3.3h-2.07c-3.09,0-3.66,1.26-3.66,2.43a2.1313,2.1313,0,0,0,2.4,2.22c2.6093,0,3.33-1.77,3.33-4.32Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M138.1027,22.59c2.5195-3.12,5.2793-6.72,7.71-9.9h-7.26v-2.82h11.22v2.79c-2.37,3.12-5.13,6.66-7.5606,9.8394h8.01l-.4492,2.79h-11.67Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M165.9415,17.34c0,4.5-2.0693,8.22-7.1992,8.22-5.19,0-7.08-3.93-7.08-8.13,0-3.9,2.19-7.8594,7.26-7.8594C163.6915,9.5708,165.9415,13.0806,165.9415,17.34Zm-10.68.03c0,3.36,1.2305,5.4,3.63,5.4,2.3106,0,3.4805-2.01,3.4805-5.4,0-3.1494-1.17-4.98-3.6006-4.98C156.4923,12.3906,155.2618,14.3706,155.2618,17.37Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M169.0021,14.67c0-3.18-.03-4.14-.06-4.8h3.4492a17.6581,17.6581,0,0,1,.12,2.13,4.6619,4.6619,0,0,1,4.38-2.43c3.12,0,4.89,1.92,4.89,5.6694V25.29h-3.5391V15.8706c0-1.89-.54-3.36-2.58-3.36-2.16,0-3.12,1.41-3.12,4.29v8.49h-3.54Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M83.8937,30.6807V43.79c0,2.6407.84,4.86,4.1689,4.86s4.08-2.1006,4.08-4.86V30.6807h3.69V43.88c0,4.32-1.83,7.71-7.83,7.71-5.8193,0-7.7695-3.09-7.7695-7.6494v-13.26Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M99.5236,40.6709c0-3.18-.03-4.14-.06-4.8h3.4492a17.6581,17.6581,0,0,1,.12,2.13,4.6619,4.6619,0,0,1,4.38-2.43c3.12,0,4.89,1.92,4.89,5.6694V51.29h-3.5391V41.87c0-1.89-.54-3.3594-2.58-3.3594-2.16,0-3.12,1.41-3.12,4.29V51.29h-3.54Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M129.1632,49.88c0,4.77-1.95,7.65-7.05,7.65-4.83,0-6.2695-2.61-6.48-4.86h3.51c.27,1.38,1.38,2.1,3.1494,2.1,2.82,0,3.42-1.7392,3.42-4.709v-1.08c-.78,1.56-2.22,2.1894-4.26,2.1894-3.66,0-6.0595-2.9394-6.0595-7.71,0-4.9493,2.88-7.8892,6.39-7.8892,2.43,0,3.48.93,3.87,1.86a11.0817,11.0817,0,0,1,.1495-1.56h3.42c-.0293.75-.06,2.46-.06,4.59Zm-6.75-1.6494c2.5195,0,3.39-1.5,3.39-4.89,0-3.1806-.54-4.83-3.27-4.83-2.07,0-3.51,1.59-3.51,4.89C119.0236,46.4,120.2833,48.2305,122.4132,48.2305Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M144.6427,47.4805a31.8314,31.8314,0,0,0,.21,3.81h-3.12a12.7421,12.7421,0,0,1-.2392-2.04c-.6006,1.35-2.1006,2.31-4.41,2.31-3.81,0-5.1-2.43-5.1-4.709,0-2.61,1.7695-5.01,7.1992-5.01h1.98V40.61c0-1.32-.3906-2.49-2.46-2.49-1.95,0-2.52.9595-2.7,2.28h-3.42c.1807-2.49,1.65-4.83,6.18-4.83,3.51,0,5.88,1.26,5.88,4.98Zm-3.3594-3.3h-2.07c-3.09,0-3.66,1.26-3.66,2.43a2.1311,2.1311,0,0,0,2.4,2.22c2.6094,0,3.33-1.77,3.33-4.3194Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M146.5636,35.8706h2.28v-4.29h3.51v4.29h2.88v2.79h-2.88v8.49c0,1.14.27,1.62,1.56,1.62a6.0745,6.0745,0,0,0,1.02-.0908v2.49a8.4788,8.4788,0,0,1-2.34.3c-2.73,0-3.75-1.1093-3.75-3.8691V38.66h-2.28Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M157.753,33.021v-3.57h3.54v3.57Zm0,2.85h3.54V51.29h-3.54Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M165.3126,40.6709c0-3.18-.03-4.14-.06-4.8h3.4493a17.6791,17.6791,0,0,1,.12,2.13,4.6619,4.6619,0,0,1,4.38-2.43c3.12,0,4.89,1.92,4.89,5.6694V51.29h-3.539V41.87c0-1.89-.54-3.3594-2.58-3.3594-2.16,0-3.12,1.41-3.12,4.29V51.29h-3.54Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M194.9523,49.88c0,4.77-1.95,7.65-7.05,7.65-4.83,0-6.27-2.61-6.48-4.86h3.51c.27,1.38,1.38,2.1,3.1494,2.1,2.82,0,3.42-1.7392,3.42-4.709v-1.08c-.78,1.56-2.22,2.1894-4.26,2.1894-3.66,0-6.06-2.9394-6.06-7.71,0-4.9493,2.88-7.8892,6.39-7.8892,2.43,0,3.48.93,3.87,1.86a11.083,11.083,0,0,1,.1494-1.56h3.42c-.0293.75-.0595,2.46-.0595,4.59Zm-6.75-1.6494c2.5195,0,3.39-1.5,3.39-4.89,0-3.1806-.54-4.83-3.27-4.83-2.07,0-3.51,1.59-3.51,4.89C184.8126,46.4,186.0724,48.2305,188.2023,48.2305Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M82.8136,71.29a3.7415,3.7415,0,0,0,4.05,3.39c2.3691,0,3.48-1.1094,3.48-2.85,0-2.01-1.11-2.85-4.3193-3.7793-4.62-1.32-6.24-2.94-6.24-5.91,0-3.63,2.85-5.73,6.75-5.73,4.77,0,6.7793,2.58,7.02,5.76h-3.57a3.1637,3.1637,0,0,0-3.51-2.94c-2.0391,0-3.06.96-3.06,2.55,0,1.6806,1.05,2.2207,4.11,3.12,5.1894,1.56,6.539,3.6,6.539,6.51,0,3.39-2.2793,6.12-7.38,6.12-4.4091,0-7.1992-2.28-7.47-6.24Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M99.9425,70.36c-.0293,2.28.9,4.41,3.33,4.41a2.8286,2.8286,0,0,0,2.94-2.07h3.48a6.244,6.244,0,0,1-6.54,4.8594c-4.89,0-6.7793-3.51-6.7793-7.98,0-3.81,1.89-8.01,6.96-8.01,5.0391,0,6.5694,3.72,6.5694,7.56,0,.15-.03,1.02-.06,1.23Zm6.51-2.4c-.09-2.01-.8994-3.6895-3.18-3.6895-2.55,0-3.18,2.16-3.27,3.6895Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M112.9327,67.0908c0-2.49,0-4.26-.0595-5.22h3.4492c.0605.6894.0908,1.68.0908,2.85a4.9373,4.9373,0,0,1,4.7695-3.12v3.6c-3.27.03-4.71,1.41-4.71,4.8906V77.29h-3.54Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M126.5226,61.8711c1.71,5.6992,2.88,9.6289,3.3291,12.0293h.03c.36-2.19,2.13-7.41,3.4794-12.0293h3.4805l-5.25,15.4189h-3.75l-5.1-15.4189Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M139.0919,59.0205V55.4512h3.54v3.5693Zm0,2.8506h3.54V77.29h-3.54Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M159.0714,72.07c-.5107,3.06-2.46,5.4893-6.57,5.4893-4.59,0-6.7793-2.9092-6.7793-7.98,0-4.7393,2.55-8.01,6.9893-8.01,4.74,0,6.21,3.36,6.33,5.64h-3.51c-.21-1.5293-.96-2.8789-2.88-2.8789-2.37,0-3.33,2.1-3.33,5.1289,0,3.7207,1.2,5.37,3.2695,5.37,1.8907,0,2.5508-1.2,2.9707-2.76Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M164.712,70.36c-.0293,2.28.9,4.41,3.33,4.41a2.8286,2.8286,0,0,0,2.94-2.07h3.48a6.244,6.244,0,0,1-6.54,4.8594c-4.89,0-6.7793-3.51-6.7793-7.98,0-3.81,1.89-8.01,6.96-8.01,5.039,0,6.5693,3.72,6.5693,7.56,0,.15-.03,1.02-.06,1.23Zm6.51-2.4c-.09-2.01-.8994-3.6895-3.18-3.6895-2.55,0-3.18,2.16-3.2695,3.6895Z" transform="translate(0 -3.2412)"/>
                <path className={logo} d="M179.922,72.6406a2.6764,2.6764,0,0,0,2.94,2.31c1.6494,0,2.46-.75,2.46-1.83,0-1.14-.9307-1.6494-3.36-2.34-3.9892-1.11-4.9492-2.52-4.9492-4.68,0-1.9805,1.26-4.53,5.6094-4.53,3.99,0,5.6094,2.07,5.79,4.59h-3.45a2.1942,2.1942,0,0,0-2.49-1.98c-1.3193,0-2.01.6-2.01,1.62,0,.93.6006,1.38,2.97,2.01,4.11,1.05,5.37,2.52,5.37,4.98,0,3.0606-2.52,4.77-6.06,4.77-3.57,0-5.9394-1.6192-6.2695-4.919Z" transform="translate(0 -3.2412)"/>
              </g>
            </g>
          </svg>
        </Link>
        <div className={links}>
          {props.cart && <Cart />}
          <AnchorLink to="/#order"><button>Ungate Now</button></AnchorLink>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = { cart: PropTypes.bool.isRequired }

export default Header
