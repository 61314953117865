import React, { useContext } from "react"
import PropTypes from "prop-types"

import { CartContext } from "./CartProvider"
import { container, description, qty, deletebtn } from "./CartItem.module.css"

const CartItem = ({ price, quantity }) => {
  const { remove } = useContext(CartContext)
  return (
    <div key={price.id} className={container}>
      <div className={description}>
        <button
          className={deletebtn}
          onClick={() => {
            remove(price.id)
          }}
        >
          &times;
        </button>
        <div>
          
            <strong>{price.product.name}</strong>
          
        </div>
        <div className={qty}>
          <span>
            ${price.unit_amount / 100} &times; {quantity}
          </span>
          <strong>${(price.unit_amount / 100) * quantity}</strong>
        </div>
      </div>
    </div>
  )
}

CartItem.propTypes = {
  price: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
}

export default CartItem
